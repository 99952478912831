import form from '@/templates/shared/_form.js'

export default class {
  static titleSpec(card) {
    const editMode = $tpu.editing.titleCardId == card.id
    return editMode ? this.editableTitle(card) : this.readOnlyTitle(card)
  }

  static readOnlyTitle(card) {
    return form.readOnlyTitle(card.name, {
      titleCardId: card.id
    }, "h3")
  }

  static editableTitle(card) {
    return form.editableTitle(card.name, null, {
      "action": "commands/custom",
      "name": "cards/update",
      "properties": {
        "cardId": card.id
      }
    })
  }

  static descriptionSpec(card) {
    const editMode = $tpu.editing.descriptionCardId == card.id
    return editMode ? this.editableDescription(card) : this.readOnlyDescription(card)
  }

  static editableDescription(card) {
    return form.editableText({
      "view": "fields/textarea",
      "styleClasses": ["outlined", "compact"],
      "width": "matchParent",
      "name": "desc",
      "value": card.desc,
      "label": "Description"
    }, {
      "action": "commands/custom",
      "name": "cards/update",
      "properties": {
        "cardId": card.id
      }
    })
  }

  static readOnlyDescription(card) {
    return form.readOnlyText({
      "view": "fields/textarea",
      "styleClasses": ["outlined", "compact"],
      "width": "matchParent",
      "readOnly": true,
      "value": card.desc,
      "label": "Description"
    }, {
      descriptionCardId: card.id
    })
  }
}
